import * as THREE from 'three';

export default class NavMenu {
  constructor(_options) {
    this.experience = _options.experience;
    this.isTouchDevice = this.experience.isTouchDevice;
    this.camera = this.experience.camera;

    this.height = window.innerHeight;
    this.x = 0;
    this.y = this.height / 2;
    this.curveX = 10;
    this.curveY = 0;
    this.targetX = 0;
    this.xitteration = 0;
    this.yitteration = 0;
    this.hoverZone = 200;
    this.expandAmount = 20;
    this.menuExpanded = false;

    this.body = document.querySelector('body');
    this.menuInner = document.querySelector('.menu__inner');
    this.menuArrow = document.querySelector('.menu__arrow');
    this.menuCross = document.querySelector('.menu__close');
    this.blob = document.querySelector('#blob');
    this.blobPath = document.querySelector('#blob-path');

    if (this.isTouchDevice) {
      this.body.classList.add('touch');
      this.touchMenuInit();
    } else {
      this.menuInit();
    }
  }

  touchMenuInit() {
    this.menuArrow.addEventListener('mousedown', () => {
      this.openMenu();
    });

    this.menuCross.addEventListener('mousedown', () => {
      this.closeMenu();
    });
  }

  menuInit() {
    window.addEventListener('mousemove', (event) => {
      this.x = event.clientX;
      this.y = event.clientY;

      if ((event.clientX / this.experience.config.width - 0.5) > -0.4) {
        this.body.classList.add('nav-ready');
        this.navReady = true;
      }
    });

    this.menuInner.addEventListener('mouseenter', () => {
      this.openMenu();
    });

    this.menuInner.addEventListener('mouseleave', () => {
      this.closeMenu();
    });
  }

  openMenu() {
    this.body.classList.add('expanded');
    this.menuExpanded = true;
    this.camera.cameraAnimation({
      targetX: -1.07,
      targetY: 0.339,
      targetZ: -3.1,
      delay: 0,
      ease: 'power2.InOut',
      duration: 1,
      targetPosition: new THREE.Vector3(1.3, 0.841, -0.235),
    });

    this.camera.focusAnimation({
      delay: 0,
      ease: 'power2.InOut',
      duration: 1,
      bokeh: 0,
    });
  }

  closeMenu() {
    this.body.classList.remove('expanded');
    this.menuExpanded = false;
    this.camera.cameraAnimation({
      delay: 0,
      ease: 'power2.InOut',
      duration: 1,
      targetPosition: new THREE.Vector3(-0.095, 0.841, -0.235),
    });

    this.camera.focusAnimation({
      delay: 0,
      ease: 'power2.InOut',
      duration: 1,
      bokeh: 0.00031,
    });
  }

  easeOutExpo(currentIteration, startValue, changeInValue, totalIterations) {
    return changeInValue * (-Math.pow(2, -10 * currentIteration / totalIterations) + 1) + startValue;
  }

  svgCurve() {
    if (!this.navReady) {
      return;
    }
    if ((this.curveX > this.x - 1) && (this.curveX < this.x + 1)) {
      this.xitteration = 0;
    } else {
      if (this.menuExpanded) {
        this.targetX = 0;
      } else {
        this.xitteration = 0;
        if (this.x > this.hoverZone) {
          this.targetX = 0;
        } else {
          this.targetX = -(((60 + this.expandAmount) / 100) * (this.x - this.hoverZone));
        }
      }
      this.xitteration += 1;
    }

    if ((this.curveY > this.y - 1) && (this.curveY < this.y + 1)) {
      this.yitteration = 0;
    } else {
      this.yitteration = 0;
      this.yitteration += 1;
    }

    this.curveX = this.easeOutExpo(this.xitteration, this.curveX, this.targetX - this.curveX, 100);
    this.curveY = this.easeOutExpo(this.yitteration, this.curveY, this.y - this.curveY, 100);

    const anchorDistance = 2000;
    const curviness = anchorDistance - 100;

    const newCurve2 = `M60,${this.height}
      H0V0h60v${this.curveY - anchorDistance}
      c0,${curviness},${this.curveX},${curviness},${this.curveX},${anchorDistance}
      S60,${this.curveY},60,${this.curveY + (anchorDistance * 2)}V${this.height}z`;

    this.blobPath.setAttribute('d', newCurve2);

    this.blob.style.width = (this.curveX + 60);
  }
}
