import * as THREE from 'three';

export default class Camera {
  constructor(_options) {
    this.experience = _options.experience;
    this.canvas = this.experience.canvas;
    this.scene = _options.experience.scene;
    this.config = this.experience.config;
    this.debug = this.experience.debug;
    this.width = this.config.width;
    this.height = this.config.height;

    this.targetPosition = new THREE.Vector3(-0.095, 0.841, -0.235);

    this.initialCameraPosition = this.config.orientation === 'landscape'
      ? new THREE.Vector3(-1.85, 1.41, -2.6)
      : new THREE.Vector3(-3.8, 1.11, -2.6);

    this.setCamera();
    this.setControls();
  }

  setCamera() {
    this.camera = new THREE.PerspectiveCamera(35, this.width / this.height, 0.1, 10000);
    this.camera.position.set(this.initialCameraPosition.x, this.initialCameraPosition.y, this.initialCameraPosition.z);
    this.camera.lookAt(this.targetPosition);
    this.scene.add(this.camera);

    // Debug Camera
    if (this.debug) {
      const cameraFolder = this.experience.gui.addFolder('Camera');
      cameraFolder.add(this.camera.position, 'x').min(-5).max(3).step(0.001);
      cameraFolder.add(this.camera.position, 'y').min(-5).max(3).step(0.001);
      cameraFolder.add(this.camera.position, 'z').min(-5).max(3).step(0.001);
      cameraFolder.add(this.targetPosition, 'x').min(-5).max(3).step(0.001)
        .name('target X')
        .onChange(() => {
          this.update();
        });
      cameraFolder.add(this.targetPosition, 'y').min(-2).max(3).step(0.001)
        .name('target Y')
        .onChange(() => {
          this.update();
        });
      cameraFolder.add(this.targetPosition, 'z').min(-2).max(3).step(0.001)
        .name('target Z')
        .onChange(() => {
          this.update();
        });
    }
  }

  cameraAnimation({
    targetX = -1.85, targetY = 1.41, targetZ = -2.6, delay = 0, ease = 'power4.inOut', duration = 2.5, targetPosition = null,
  }) {
    const camera = this;
    // eslint-disable-next-line no-undef
    const tween = gsap.to(this.camera.position, {
      duration,
      x: targetX,
      y: targetY,
      z: targetZ,
      delay,
      ease,
      onStart() {
      },
      onUpdate() {
      },
      onComplete() {
        camera.introCompleted = true;
      },
    });
    tween.play();

    if (targetPosition) {
      // eslint-disable-next-line no-undef
      const tween2 = gsap.to(this.targetPosition, {
        duration,
        x: targetPosition.x,
        y: targetPosition.y,
        z: targetPosition.z,
        delay,
        ease,
        onStart() {
        },
        onUpdate() {
        },
        onComplete() {
        },
      });
      tween2.play();
    }
  }

  focusAnimation({
    delay = 0, ease = 'power4.inOut', duration = 2.5, bokeh,
  }) {
    // eslint-disable-next-line no-undef
    const tween3 = gsap.to(this.experience.renderer.dof.circleOfConfusionMaterial.uniforms.focusDistance, {
      duration,
      value: bokeh,
      delay,
      ease,
      onStart() {
      },
      onUpdate() {
      },
      onComplete() {
      },
    });
    tween3.play();
  }

  setControls() {
    if (!('ontouchstart' in window)) {
      // Cursor
      this.cursor = {
        x: 0,
        y: 0,
      };

      window.addEventListener('mousemove', (event) => {
        const xPos = event.clientX / this.width - 0.5;
        const yPos = -(event.clientY / this.height - 0.5);
        this.cursor.x = xPos;
        this.cursor.y = yPos;
      });
    }
  }

  update() {
    if (!('ontouchstart' in window)) {
      this.camera.position.x = this.initialCameraPosition.x + (this.cursor.x * 0.3);
      this.camera.position.y = this.initialCameraPosition.y + (this.cursor.y * 0.3);
    }

    this.camera.lookAt(this.targetPosition);
  }
}
