/* eslint-disable no-return-assign */
import * as THREE from 'three';
import {
  DepthOfFieldEffect, GodRaysEffect, EffectComposer, EffectPass, RenderPass,
} from 'postprocessing';

export default class Renderer {
  constructor(_options) {
    this.experience = _options.experience;
    this.canvas = this.experience.canvas;
    this.config = this.experience.config;
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;
    this.debug = this.experience.debug;
    this.setRender();
    this.resize();
  }

  setRender() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
    });

    this.renderer.setSize(this.config.width, this.config.height);
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setPixelRatio(this.renderer.capabilities.isWebGL2 ? this.config.pixelRatio : 2);

    window.addEventListener('resize', () => {
      // Update sizes
      this.config.width = window.innerWidth;
      this.config.height = window.innerHeight;

      // Update camera
      this.camera.camera.aspect = this.config.width / this.config.height;
      this.camera.camera.updateProjectionMatrix();

      // Update renderer
      this.resize();
    });

    this.postProcessing();
  }

  render() {
    this.renderer.render(this.scene, this.camera.camera);
  }

  postProcessing() {
    const options = {
      focalLength: 0.00031,
      focusDistance: this.config.orientation === 'landscape' ? 0.00025 : 0.00043,
      bokehScale: 4.5,
      width: this.config.width,
      height: this.config.height,
    };
    this.dof = new DepthOfFieldEffect(this.camera.camera, options);

    const verticesOfCube = [
      -1, -1, -1, 1, -1, -1, 1, 1, -1, -1, 1, -1,
      -1, -1, 1, 1, -1, 1, 1, 1, 1, -1, 1, 1,
    ];
    const indicesOfFaces = [
      2, 1, 0, 0, 3, 2,
      0, 4, 7, 7, 3, 0,
      0, 1, 5, 5, 4, 0,
      1, 2, 6, 6, 5, 1,
      2, 3, 7, 7, 6, 2,
      4, 5, 6, 6, 7, 4,
    ];
    const geometry = new THREE.PolyhedronGeometry(verticesOfCube, indicesOfFaces, 0.4, 1);
    const material = new THREE.PointsMaterial({ color: 0xffcc80 });
    const mesh = new THREE.Points(geometry, material);
    mesh.position.set(10.26, 2.025, 4.2);
    this.scene.add(mesh);

    const godRayOptions = {
      density: 1.912,
      decay: 0.904,
      clampMax: 3,
    };
    const godRays = new GodRaysEffect(this.camera.camera, mesh, godRayOptions);

    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera.camera));
    this.composer.addPass(new EffectPass(this.camera.camera, this.dof));
    this.composer.addPass(new EffectPass(this.camera.camera, godRays));

    this.composer.addPass(new RenderPass(this.scene, this.camera.camera));
    this.composer.addPass(new EffectPass(this.camera.camera, this.dof));
    this.composer.addPass(new EffectPass(this.camera.camera.set, godRays));

    if (this.debug) {
      this.experience.gui
        .add(options, 'bokehScale')
        .min(0)
        .max(10)
        .step(0.001)
        .onChange((f) => this.dof.bokehScale = f);

      this.experience.gui
        .add(options, 'focalLength')
        .min(0)
        .max(0.002)
        .step(0.00001)
        .onChange((f) => this.dof.circleOfConfusionMaterial.uniforms.focalLength.value = f);

      this.experience.gui
        .add(options, 'focusDistance')
        .min(0)
        .max(0.02)
        .step(0.00001)
        .onChange((f) => this.dof.circleOfConfusionMaterial.uniforms.focusDistance.value = f);

      this.experience.gui
        .add(godRayOptions, 'density')
        .min(0)
        .max(3)
        .step(0.0001)
        .onChange((f) => godRays.godRaysPass.screen.material.uniforms.density.value = f);

      this.experience.gui
        .add(godRayOptions, 'decay')
        .min(0)
        .max(3)
        .step(0.0001)
        .onChange((f) => godRays.godRaysPass.screen.material.uniforms.decay.value = f);

      this.experience.gui
        .add(godRayOptions, 'clampMax')
        .min(0)
        .max(3)
        .step(0.0001)
        .onChange((f) => godRays.godRaysPass.screen.material.uniforms.clampMax.value = f);

      this.experience.gui
        .add(mesh.position, 'x')
        .min(-20)
        .max(20)
        .step(0.0001);

      this.experience.gui
        .add(mesh.position, 'y')
        .min(-20)
        .max(20)
        .step(0.0001);

      this.experience.gui
        .add(mesh.position, 'z')
        .min(-20)
        .max(20)
        .step(0.0001);
    }
  }

  resize() {
    this.composer.setSize(this.config.width, this.config.height);
    this.renderer.setSize(this.config.width, this.config.height);
    this.renderer.setPixelRatio(this.renderer.capabilities.isWebGL2 ? this.config.pixelRatio : 2);
  }

  destroy() {
    this.renderer.dispose();
  }
}
